<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>处方管理</el-breadcrumb-item>
            <el-breadcrumb-item>药品管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="add('new','')" size="small">添加</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <!-- <el-table-column label="编号" prop="id" width="80"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="drug_code" label="药品编号"></el-table-column>
            <el-table-column prop="drug_name" label="药品名"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="add('bj',scope.row)">修改
                        </el-button>
                        <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">
                            删除 </el-button> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="total" :hide-on-single-page="value">
            </el-pagination>
        </p>
        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form" class="hdtc" label-width="170px">
                <el-form-item label="请输入药名">
                    <el-input style="float: left;" v-model="form.drug_name" autocomplete="off" placeholder="请输入药名">
                    </el-input>
                </el-form-item>
                <el-form-item label="请输入药品编号">
                    <el-input style="float: left;" v-model="form.drug_code" autocomplete="off" placeholder="请输入药品编号">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;form = {}">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        inject:['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                total: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                form: {
                    drug_name: '',
                    drug_code: ''
                },
                dialogFormVisible: false,
                title: '添加药品',
                type: '',
                search: ""
            }
        },
        mounted() {
            this.getdata(1);
        },
        methods: {
            getdata(page) {
                this.axios.get("/docpres/get_gu_docpres_drugs?page=" + page + "&size=" + this.PageSize)
                    .then((rs) => {
                        console.log(rs.data)
                        if (rs.data.code == 0) {
                            this.list = rs.data.result;
                            this.total=rs.data.count;
                        } else {
                            console.log(rs);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            add(type, item) {
                this.type = type;
                this.dialogFormVisible = true;
                if (type != 'new') {
                    this.title = '修改药品';
                    this.form.drug_name = item.drug_name;
                    this.form.drug_code = item.drug_code;
                    this.form.id = item.id;
                } else {
                    this.title = '添加药品';
                    this.form.drug_name = '';
                    this.form.drug_code = '';
                }
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该药品, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log();
                    this.axios.post("/tag/delete_gu_tag", this.qs.stringify({ id: row.id, tag: row.tag }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage);
            },
            sub() {
                let url = '';
                let str = '';
                var form = {
                    drug_name : this.form.drug_name ,
                    drug_code : this.form.drug_code
                }
                if (this.type == 'new') {
                    url = "/docpres/insert_gu_docpres_drug";
                    str = '添加成功'
                } else {
                    url = "/docpres/update_gu_docpres_drug";
                    str = "修改成功";
                    form.id = this.form.id;
                }

                console.log(form);

                this.axios.post(url, this.qs.stringify(form))
                    .then((rs) => {
                        console.log(rs);
                        if (rs.data.code == 0) {
                            this.dialogFormVisible = false;
                            this.form = {};
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        }

    }
</script>

<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .hdtc {
        width: 80%;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>